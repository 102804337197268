import React from "react"
import { MainTitleStyle } from "../styles/mainTitle"

const MainTitle = props => (
  <MainTitleStyle
    colorSmall={props.colorSmall}
    colorBig={props.colorBig}
    className={props.className}
  >
    <h4>{props.smallText}</h4>
    <h1 className={props.h1}>{props.bigText}</h1>
    {props.bigText2 ? <h1 className={props.h1}>{props.bigText2}</h1> : ""}
  </MainTitleStyle>
)

export default MainTitle
