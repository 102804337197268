import React from "react"
import { Link, graphql } from "gatsby"

import SEO from "../components/seo"
import BudgetEstimate from "../components/budgetEstimate"
import Arrow from "../images/arrow.svg"
import ScrollAnimation from "react-animate-on-scroll"

import { ClientsStyle } from "../styles/clients"
import { colors } from "../styles/variables"

/* SVG - Clients */
import Elecnor from "../images/clientes/elecnor.svg"
import Sonae from "../images/clientes/sonae.svg"
import Gestamp from "../images/clientes/gestamp.svg"
import Civilria from "../images/clientes/civilria.svg"
import Aleluia from "../images/clientes/new/aleluia-ceramicas.svg"
import Ambitermo from "../images/clientes/new/ambitermo.svg"
import ClosedLoop from "../images/clientes/new/closed-loop.svg"
import Correcta from "../images/clientes/new/correcta.svg"
import Energy from "../images/clientes/new/energy-top.svg"
import Enermundo from "../images/clientes/new/enermundo.svg"
import Est from "../images/clientes/new/est.svg"
import Fixup from "../images/clientes/new/fixup.svg"
import Iluminho from "../images/clientes/new/iluminho.svg"
import Jhomea from "../images/clientes/new/jhomea.svg"
import Layertech from "../images/clientes/new/layertech.svg"
import MediaMarkt from "../images/clientes/new/media-markt.svg"
import Motrinde from "../images/clientes/new/motrinde.svg"
import Nogueira from "../images/clientes/new/nogueira.svg"
import Protecnico from "../images/clientes/new/protecnico.svg"
import Serimm from "../images/clientes/new/serimm.svg"
import SPlus from "../images/clientes/new/s-plus.svg"
import Tam from "../images/clientes/new/tam.svg"
import Teixeira from "../images/clientes/new/teixeira.svg"
import Tupai from "../images/clientes/new/tupai.svg"


/* SVG - Clients */

const Clients = props => {
  const { clientsArea, clientsArea_tooltip, budgetEstimate } =
    props.data.dataJson || {}

  return (
    <>
      <SEO
        title="Clientes"
        image="/images/clientes.jpg"
        imageWidth="1200"
        imageHeight="628"
        pathname="/clientes"
        description="A Sicarpo acredita que as parcerias de sucesso resultam da dedicação e do respeito. Parcerias que são preservadas em prol de um crescimento mútuo."
      />

      {props.data && props.data.dataJson ? (
        <ClientsStyle noMarginTop={props.noMarginTop}>
          {/* Start - Manutenção Industrial */}
          <div
            className="startArea"
            style={{ backgroundImage: `url(${clientsArea.img.publicURL})` }}
          >
            <div className="text">
              <ScrollAnimation
                animateIn="fadeInDown"
                offset={0}
                duration={1.2}
                animateOnce={true}
              >
                <h1>{clientsArea.title}</h1>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInDown"
                offset={0}
                duration={1.2}
                animateOnce={true}
                delay={200}
              >
                <p>{clientsArea.textp1}</p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInDown"
                offset={0}
                duration={1.2}
                animateOnce={true}
                delay={200}
              >
                <p>{clientsArea.textp2}</p>
              </ScrollAnimation>
            </div>
          </div>
          {/* End   - Manutenção Industrial */}

          {/* Start - Tooltip */}
          <ScrollAnimation
            className="tooltipArea"
            animateIn="fadeInUp"
            offset={0}
            duration={1.2}
            animateOnce={true}
          >
            <div className="text">
              <h1>{clientsArea_tooltip.title}</h1>
              <p>{clientsArea_tooltip.textp1}</p>
            </div>
            <ul className="tooltipAllClients">
              {/* <li className="logoClient">
                <a
                  href="http://www.altri.pt/"
                  alt="Altri"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "} 
                  <Aleluia />
                </a>
              </li> */}
              <li className="logoClient">
                  {" "}
                  <Aleluia />
              </li>
              <li className="logoClient">
                  {" "}
                  <Ambitermo />
              </li>
              <li className="logoClient">
                  {" "}
                  <Civilria />
              </li>
              <li className="logoClient">
                  {" "}
                  <ClosedLoop />
              </li>
              <li className="logoClient">
                  {" "}
                  <Correcta />
              </li>
              <li className="logoClient">
                  {" "}
                  <Elecnor />
              </li>
              <li className="logoClient">
                  {" "}
                  <Energy />
              </li>
              <li className="logoClient">
                  {" "}
                  <Enermundo />
              </li>
              <li className="logoClient">
                  {" "}
                  <Est />
              </li>
              <li className="logoClient">
                  {" "}
                  <Fixup />
              </li>
              <li className="logoClient">
                  {" "}
                  <Gestamp />
              </li>
              <li className="logoClient">
                  {" "}
                  <Iluminho />
              </li>
              <li className="logoClient">
                  {" "}
                  <Jhomea />
              </li>
              <li className="logoClient">
                  {" "}
                  <Layertech />
              </li>
              <li className="logoClient">
                  {" "}
                  <MediaMarkt />
              </li>
              <li className="logoClient">
                  {" "}
                  <Motrinde />
              </li>
              <li className="logoClient">
                  {" "}
                  <Nogueira />
              </li>
              <li className="logoClient">
                  {" "}
                  <Protecnico />
              </li>
              <li className="logoClient">
                  {" "}
                  <Serimm />
              </li>
              <li className="logoClient">
                  {" "}
                  <Sonae />
              </li>
              <li className="logoClient">
                  {" "}
                  <SPlus />
              </li>
              <li className="logoClient">
                  {" "}
                  <Tam />
              </li>
              <li className="logoClient">
                  {" "}
                  <Teixeira />
              </li>
              <li className="logoClient">
                  {" "}
                  <Tupai />
              </li>
            </ul>
            <Link to="/contacto" className="tooltipButtonContainer">
              <span className="tooltipButton">
                Fale connosco <Arrow className="tooltipButtonArrow" />
              </span>
            </Link>
          </ScrollAnimation>
          {/* End   - Tooltip */}

          {/* Start - Soluções à Medida / Budget */}
          <BudgetEstimate
            bigText={budgetEstimate.mainTitle.bigText}
            bigText2={budgetEstimate.mainTitle.bigText2}
            colorBig={colors.titleLightBig}
            className="titleBudget"
            h1="titleBudgetH1"
            firstParagraph={budgetEstimate.text.firstParagraph}
          />
          {/* End   - Soluções à Medida / Budget */}
        </ClientsStyle>
      ) : (
        <div className="isLoading">A carregar...</div>
      )}
    </>
  )
}

export default Clients

export const ClientsQuery = graphql`
  query ClientsQuery {
    dataJson {
      budgetEstimate {
        mainTitle {
          smallText
          bigText
          bigText2
        }
        text {
          firstParagraph
        }
      }
      clientsArea {
        img {
          publicURL
        }
        title
        textp1
        textp2
      }
      clientsArea_tooltip {
        title
      }
    }
  }
`
