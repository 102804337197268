import styled from "styled-components"

export const MainTitleStyle = styled.div`
  h4 {
    font-weight: 500;
    text-transform: uppercase;
    color: ${props => props.colorSmall};
    font-size: 4rem;
  }

  h1 {
    text-transform: uppercase;
    font-family: "Monument";
    font-weight: 300;
    letter-spacing: 3px;
    color: ${props => props.colorBig};
    font-size: 8rem;
    line-height: 1;
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    h4 {
      font-size: 1.8rem;
    }
    h1 {
      font-size: 6rem;
      line-height: 65px;
    }
  }
  @media screen and (max-width: 768px) {
    h4 {
      font-size: 1.5rem;
    }
    h1 {
      font-size: 4.5rem;
      line-height: 50px;
    }
  }
`
